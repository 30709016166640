<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2><span class="blue">Vatandaşlar</span> için İleti Yönetim Sistemi 


        </h2>

        <div
          class="videoplay"
          @click="
            SET_MODAL('ModalVideoVatandas');
            SET_NOTIFY_REQUESTER_TYPE(0);
          "
        >
          <div>
            <img class="playthumb" src="@/assets/img/video_thumb_vatandas.png" alt />
            <img class="playbtn" src="@/assets/img/video_play.svg" alt />
          </div>
        </div>

        <div class="staticbox">
          <div class="p70">
            <h3>Vatandaşlar için tanıtım filmimizi izleyin</h3>
            <p>
              İleti Yönetim Sistemi hakkında bilgi edinmek için tanıtım
              filmimizi izleyin.
            </p>
          </div>
        </div>

        <social />

        <!-- <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/vt-01.svg" alt />
          </div>

          <div class="p70">
            <h3>Ticari elektronik ileti izinleri tüm platformlarda</h3>
            <p>
              Vatandaşlar İleti Yönetim Sistemi Web, İleti Yönetim Sistemi
              Mobil, çağrı merkezi, kısa numara, kısa link ve e-Devlet kapısı
              üzerinden onay ve ret işlemlerini kolaylıkla yapabilecektir.
              <br />Hangi yöntem tercih edilirse edilsin, tüm işlemler
              senkronize olarak diğer platformlarda da anında
              görüntülenebilecektir.
            </p>
          </div>
        </div> -->

        <div class="detailbox alt viys">
          <div class="infoline">
            <img src="@/assets/img/hero/zc-08.svg" alt="Vatandaşlar için ileti Yönetim Sistemi" />
            <div class="desc">
              <h4>Vatandaşlar için ileti Yönetim Sistemi Açıldı!</h4>
              <p>Giriş yaparak hemen ticari elektronik ileti izinlerinizi yönetebilirsiniz.</p>
            </div>
            <div class="btn">
              <a href="http://vatandas.iys.org.tr/" >Vatandaş Girişi</a>
            </div>
          </div>
        </div>

        <div class="staticbox mb2">
          <div class="full">
            <img src="@/assets/img/icons/vt-03.svg" alt />
          </div>

          <div class="p70">
            <h3>Ticari elektronik ileti izinleri tek noktada ve güvende</h3>
            <p>İYS, vatandaşların tüm ticari elektronik ileti onaylarını tek noktada ve güvenli bir biçimde tutmaktadır.
Böylece vatandaşlar aynı yerde tüm onay ve ret işlemlerini kolaylıkla yapabilir.
Bu amaçla vatandaşlar İYS İnternet sitesi, e-Devlet veya İYS Mobil uygulamasını kullanabilir.

            </p>
          </div>
        </div>

        <!-- <div class="staticbox">
          <div class="full">
            <img src="@/assets/img/icons/vt-02.svg" alt />
          </div>

          <div class="p70">
            <h3>Şikâyet yönetimi</h3>
            <p>
              Ticari elektronik iletilere ilişkin şikâyetler İleti Yönetim
              Sistemi Web, İleti Yönetim Sistemi Mobil, çağrı merkezi ve
              e-Devlet kapısı üzerinden doğrudan bakanlığa iletilecektir.
              <br />Şikâyetlere ilişkin süreçler hızlanacak, sonuçlanan her
              şikâyet için <tool-tip-hs></tool-tip-hs>lar'a ve vatandaşlara
              bilgi verilecektir.
            </p>
          </div>
        </div> -->

      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <!-- <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Social from "../../genel/Social";

export default {
  name: "Homepage",
  components: { Social },

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");
    },
  },
};
</script>

<style scoped>

.formcontainer {
    padding-top: 50px;
}

.viys { max-width: 970px;}

.viys.detailbox .infoline img {
    width: 15%;
    margin: -60px 0 -0.4vw 20px;
}


.viys.detailbox .infoline .desc {
    flex-direction: column;
}

</style>
