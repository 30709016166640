var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"formcontainer staticpage"},[_c('div',{staticClass:"formbox"},[_vm._m(0),_c('div',{staticClass:"videoplay",on:{"click":function($event){_vm.SET_MODAL('ModalVideoVatandas');
            _vm.SET_NOTIFY_REQUESTER_TYPE(0);}}},[_vm._m(1)]),_vm._m(2),_c('social'),_vm._m(3),_vm._m(4)],1)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('span',{staticClass:"blue"},[_vm._v("Vatandaşlar")]),_vm._v(" için İleti Yönetim Sistemi ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"playthumb",attrs:{"src":require("@/assets/img/video_thumb_vatandas.png"),"alt":""}}),_c('img',{staticClass:"playbtn",attrs:{"src":require("@/assets/img/video_play.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staticbox"},[_c('div',{staticClass:"p70"},[_c('h3',[_vm._v("Vatandaşlar için tanıtım filmimizi izleyin")]),_c('p',[_vm._v(" İleti Yönetim Sistemi hakkında bilgi edinmek için tanıtım filmimizi izleyin. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailbox alt viys"},[_c('div',{staticClass:"infoline"},[_c('img',{attrs:{"src":require("@/assets/img/hero/zc-08.svg"),"alt":"Vatandaşlar için ileti Yönetim Sistemi"}}),_c('div',{staticClass:"desc"},[_c('h4',[_vm._v("Vatandaşlar için ileti Yönetim Sistemi Açıldı!")]),_c('p',[_vm._v("Giriş yaparak hemen ticari elektronik ileti izinlerinizi yönetebilirsiniz.")])]),_c('div',{staticClass:"btn"},[_c('a',{attrs:{"href":"http://vatandas.iys.org.tr/"}},[_vm._v("Vatandaş Girişi")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staticbox mb2"},[_c('div',{staticClass:"full"},[_c('img',{attrs:{"src":require("@/assets/img/icons/vt-03.svg"),"alt":""}})]),_c('div',{staticClass:"p70"},[_c('h3',[_vm._v("Ticari elektronik ileti izinleri tek noktada ve güvende")]),_c('p',[_vm._v("İYS, vatandaşların tüm ticari elektronik ileti onaylarını tek noktada ve güvenli bir biçimde tutmaktadır. Böylece vatandaşlar aynı yerde tüm onay ve ret işlemlerini kolaylıkla yapabilir. Bu amaçla vatandaşlar İYS İnternet sitesi, e-Devlet veya İYS Mobil uygulamasını kullanabilir. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"staticcolumn"},[_c('div')])
}]

export { render, staticRenderFns }